  .banner {
    margin: 0;
    width: auto;
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(3,3,48,1) 0%, rgba(0,212,255,1) 100%);
}
  
  .banner__content {
    padding: 20px;
    max-width: 130px;
    margin: 0 auto;
    display: flex;
  }
  
  .banner__text {
    color: #ffffff;
    font-size: xxx-large;
    flex-grow: 1;
    line-height: 1.4;
    font-family: "Quicksand", sans-serif;
  }
  
  a:link {
    text-decoration: none;
    color: black;
  }